.Sections {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    flex-wrap: wrap;
}

.circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: white;
    border: 16px solid #3498db;
    color: #3498db;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    margin: 10px;
    text-align: center;
    font-weight: bold;
}
.CRTDHS-logos{ 
    width: 50px;
}
.CRTDH-logo-name{
    font-size: 2rem;
    margin-bottom: 10px;
}
.close-button-centers{
    position: absolute;
    top:10px;
    right: 10px;
    cursor: pointer;
}
@media screen and (max-width: 1100px) {
    

    .circle {
        width: 150px;
        height: 150px;
        border: 8px solid #3498db;
        font-size: 18px;
    }
}

.list-popup {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 5px;
    z-index: 1;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
@media all and (max-width:768px) {
    .list-popup{
        width: 90%;
    }
}