.workshop{
    display: flex;
    width: 80%;
    margin: 100px auto;
}
.workshopPost-left,.workshopPost-right{
    width: 50%;
}
.workshopPost-heading{
    font-size: 3.5rem;
    font-weight: 600;
}
.workshopPost-date-place{
    font-size: 2rem;
    margin: 30px 0px;
}
.workshopPost-info{
    font-size: 2rem;
    color: #666666;
}
.workshopPost-link{
    font-size: 2rem;
}
.workshoPost-img{
    width: 80%;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
}
.workshopPost-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media all and (max-width:991px) {
    .workshop{
        flex-direction: column-reverse;
    }
    .workshopPost-left,.workshopPost-right{
        width: 100%;
    }
    .workshoPost-img{
        width: 100%;
    }
    .workshopPost-heading{
        font-size: 2.5rem;
    }
}
@media all and (max-width:479px) {
    .workshop{
        width: 90%;
    }
    .workshoPost-img{
        width: 100%;
        height: 300px;
        margin:20px 0px;
    }
}