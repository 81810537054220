.chatbox-logo {
    position: fixed;
    bottom: 30px;
    right: 30px;
    margin: 0px;
    background-color: green;
    color: #fff;
    padding: 20px 20px 20px 40px;
    width: 200px;
    height: 60px;
    border-radius: 10px;
    cursor: pointer;
    z-index: 25;
}

.chatbox-logo p {
    margin: 0px;
    font-size: 2rem;
    font-weight: 500;
}

.chatbox-icon {
    height: 35px;
    width: 35px;
}

.chatbox-form {
    transform: translateX(400px);
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 20;
    border-radius: 15px;
    background-color: #F0F0F0;
    height: 420px;
    width: 370px;
    padding: 20px;
    transition: 300ms all;
}

.chat {
    background-color: #19C37D;
    color: #f7f7f8;
    width: 150px;
    padding: 10px;
    margin: auto;
    text-align: center;
    font-size: 2.1rem;
    border-radius: 15px;
}

.chat-screen {
    background-color: #f7f7f8;
    width: 100%;
    height: 320px;
    margin-top: 10px;
    border-radius: 15px;
    padding: 20px;
    font-weight: 500;
    overflow-y: scroll;
    transition: 300ms all;
}

.chat-text {
    font-size: 2rem;
}

.yes-no-buttons {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    margin-top: 30px;
}

.but {
    color: #fff;
    width: fit-content;
    padding: 10px;
    text-align: center;
    font-size: 1.8rem;
    border-radius: 15px;
    cursor: pointer;
}

.yes-but {
    background-color: #19C37D;

}

.no-but {
    background-color: #f00;
}

.window-2,
.window-3 {
    display: none;
}

.chat-box-chat-but {
    margin-bottom: 10px;
    width: 60%;
    font-size: 1.8rem;
    padding: 5px 15px;
    border: 2px solid #387cf5;
    border-radius: 20px;
    font-size: 400;
    cursor: pointer;
}

.form-name {
    font-size: 2rem;
}

.name-input {
    border: 1px solid #000;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    padding: 10px;
    font-size: 1.8rem;
}

.query-input {
    border: 1px solid #000;
    border-radius: 20px;
    padding: 10px;
    font-size: 1.8rem;
}

.form-submit-but {
    margin-top: 10px;
    border: none;
    padding: 7px 15px;
    font-size: 2rem;
    border-radius: 10px;
    font-weight: 400;
    background-color: #19C37D;
    color: #fff;
}

.chat-screen::-webkit-scrollbar {
    width: 10px;
}

.inVisible {
    transform: translateX(400px);
    transition: 300ms all;
}

.close-button {
    position: fixed;
    right: 15px;
    bottom: 380px;
    cursor: pointer;
}

@media all and (max-width:479px) {}