.statistics-page{
    position: absolute;
    bottom: calc(50vh - 250px);
    left:calc(80vw - 250px);
    background-color: #ffffff00;
    width: 420px;
    height: 500px;
    border-radius: 15px;
    padding: 20px;
}
.statistics{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.statistics-head{
    font-weight: bolder;
    color: white;
    background-color: rgba(0, 0, 0, 0.644);
    text-align: center;
    font-size: 2.5rem;
    padding: 0.5rem 0;
    border-radius: 15px;
}
.statistics-div{
    width: 100%;
    height: 70px;
    border-radius: 15px;
    margin-bottom: 15px;
    display: flex;
    padding: 10px;
}
.stat-text{
    color: #fff;
    font-size: 2rem;
}
.stat-img{
    width: 55px;
    height: 55px;
    margin-right: 30px;
}
.stat-1{
    background-color: #9a64c6;
}
.stat-2{
    background-color: #f5b015;
}
.stat-3{
    background-color: #1d9ac6;
}
.stat-4{
    background-color: #f44d44;
}
.stat-5{
    background-color: #79992c;
}
.stat-6{
    background-color: orange;
}

@media all and (max-width:1200px) {
    .CRTDH-name{
        top: 27vh;
    }
    .statistics-page{
        bottom: 7vh;
        left:5vw;
        width: 90vw;
        height: fit-content;
        padding: 20px;
    }
    .statistics{
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .statistics-div{
        width: 28%;
        margin: 20px;
        height: 110px;
    }
}
@media all and (max-width:880px) {
    .CRTDH-name{
        top: 27vh;
    }
    .statistics-div{
        width: 27%;
        margin: 15px;
        height: 100px;
    }
    .statistics-page{
        bottom: 5vh;
        left:5vw;
        width: 90vw;
        border-radius: 15px;
        padding: 20px;
    }
    .stat-img{
        width: 50px;
        height: 50px;
    }
    .stat-text{
        font-size: 1.8rem;
    }
}
@media all and (max-width:768px) {
    .statistics-div{
        flex-direction: column;
        align-items: center;
        width: 27%;
        margin: 15px;
        height: 150px;
        overflow-y: scroll;
    }
    .stat-img{
        height: 45px;
        width: 45px;
        margin-right: 0px;
    }
    .stat-text{
        text-align: center;
    }
}
@media all and (max-width:576px) {
    .statistics-page{
        width: 80vw;
        left: 10vw;
    }
    .statistics-div{
        width: 27%;
        margin: 10px;
        height: 150px;
    }
    .stat-text{
        font-size: 1.6rem;
    }
}
@media all and (max-width:450px) {
    .statistics-page{
        width: 85vw;
        left: 7.5vw;
    }
    .statistics-div{
        width: 26%;
        margin:10px;
        height: 110px;
    }
    .stat-img{
        height: 35px;
        width: 35px;
    }
    .stat-text{
        font-size: 1.3rem;
    }
}