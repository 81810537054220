.heading {
  margin-top: 50px;
  margin-left: 250px;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.card-title{
  font-size: 2.5rem;
  font-weight: bolder;
  margin-bottom: 15px;
}

.carousel-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 80%;
}

.carousel-card {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 40%;
  margin: 10px;
  height: 650px;
  font-family: 'open sans';

}
.carousel-card1 {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 40%;
  margin: 10px;
  height: 800px;
  font-family: 'open sans';
  justify-content: flex-start;
  overflow-y: scroll;
  text-align: justify;
}

.card-content,
.card-info {
  font-size: 1.5rem;
}

.card-content1{
  width: 80%;
}

.know-more {
  font-size: 2rem;
}

.carousel-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.update-img{
  height: 95%;
}
.update-img1 {
  max-width: 95%;
  max-height: 95%;
}

.ytVideo{
  width: 90%;
  height: 70%;
  margin: auto;
}
.connect-with{
  position: absolute;
  top: 0px;
  left: 10px;
  font-size: smaller;
}
/* button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px; 
  }
  
  button:hover {
    background-color: #0056b3;
  } */

.page-indicator {
  font-weight: bold;
  font-size: 16px;
}

.previous-carousel-button,
.next-carousel-button {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: 0px;
  position: relative;
  bottom: 325px;
}

.previous-carousel-button {
  right: 40vw;
}

.next-carousel-button {

  left: 40vw;
}

@media all and (max-width:991px) {
  .carousel-card {
    width: 70%;
  }
  .carousel-card1 {
    width: 70%;
  }
}

@media all and (max-width:991px) {
  .card-title {
    font-size: 25px;
  }

  .card-info,
  .card-content {
    font-size: 1.6rem;
  }

  .know-more {
    font-size: 1.6rem;
  }
}
@media all and (max-width:768px) {
 
  .carousel-card{
    justify-content: flex-start;
    height: 350px;
    overflow-y: scroll;
    padding: 30px 20px;
  }
  .carousel-card1{
    justify-content: flex-start;
    height: 350px;
    overflow-y: scroll;
    padding: 30px 20px;
  }
  .previous-carousel-button,
  .next-carousel-button {
   bottom:200px;
}
}
@media all and (max-width:576px) {
  .carousel-card {
    width: 80%;
  }
  .carousel-card1  {
    width: 80%;
  }

  .card-title {
    font-size: 20px;
  }

  .card-info,
  .card-content {
    font-size: 1.5rem;
  }

  .know-more {
    font-size: 1.3rem;
  }
}