.reserach-page{
    margin-top: 80px;
}
.research{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.research-div{
    width: 400px;
    height: 500px;
    margin: 30px 20px;
    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.research-div-1{
    background-image: url('../Assests//Images/banner/banner-8.jpg');
}
.research-div-2{
    background-image: url('../Assests//Images/banner/banner-4.jpg');
}
.research-div-3{
    background-image: url('../Assests//Images/banner/banner-5.jpg');
}
.research-div-4{
    background-image: url('../Assests//Images/banner/banner-6.jpg');
}
.research-div-5{
    background-image: url('../Assests//Images//research-areas/low-cost-machining.jpg');
}
.overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    transition: 100ms all;
    padding: 30px;
}
.research-div:hover .overlay{
    background-color: rgba(0, 0, 0, 0.3); 
}
.reserach-area-name{
    font-size: 3.2rem;
    font-weight: 500;
    font-family: Inter;
    color: #fff;
}
.reserach-subarea-name{
    font-size: 2rem;
    font-weight: 500;
    font-family: Inter;
    color: #fff;
}
.research-text{
    width: 80vw;
    margin: auto;
    padding: 30px;
}
.research-video{
    margin-top: 20px;
    width: 100%;
    height: 500px;
    aspect-ratio: 1/0.5 !important;
}
.yt-video-div{
    margin-top: 30px;
    width: calc(90vw - 60px);
    display: flex;
    justify-content: center;
}
.research-video{
    margin: 0px 20px;
}
.research-divs{
    display: flex;
    flex-wrap: wrap;
    width: 90vw;
    justify-content: space-evenly;
}
@media all and (max-width:768px) {
    .research-div{
        width: 400px;
        height: 400px;
    }
    .reserach-area-name{
        font-size: 2.8rem;
    }
    .reserach-subarea-name{
        font-size: 1.7rem;
    }
    .research-text{
        width: 90vw;
        padding: 10px;
    }
    
}
@media all and (max-width:479px) {
    .research-div{
        width: 350px;
        height: 400px;
    }
    .research-video{
        height: 280px;
        width: 100%;
    }
}