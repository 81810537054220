/* excel-like-table.css */

.excel-like-table {
    font-size: 1.8rem;
    width: 100%;
    border-collapse: collapse;
  }
  
  .excel-like-table th,
  .excel-like-table td {
    padding: 8px 12px;
    border: 1px solid #ccc;
    text-align: left;
  }
  
  .excel-like-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .excel-like-table tr:nth-child(even) {
    background-color: #f5f5f5;
  }
  
  .excel-like-table tr:hover {
    background-color: #e0e0e0;
  }
  
  /* Style table borders */
  .excel-like-table th,
  .excel-like-table td {
    border: 1px solid #ddd; /* Light gray border */
  }
  
  /* Style header border-bottom */
  .excel-like-table th {
    border-bottom: 2px solid #000; /* Black border */
  }
  .Instruments{
    scroll-margin-top: 130px;
  }
@media all and (max-width:991px) {
    /* .excel-like-table {
        font-size: 1.5rem;
    } */
    .list-text{
      padding: 30px;
    }
}
@media all and (max-width:768px) {
    /* .excel-like-table {
        font-size: 1.2rem;
    } */
}
@media all and (max-width:576px) {
    /* .excel-like-table {
        font-size: 0.8rem;
    } */
}
@media all and (max-width:479px) {
    /* .excel-like-table {
        font-size: 0.6rem;
    } */
}