@import url('https://fonts.googleapis.com/css2?family=Inclusive+Sans&display=swap');
.CRTDH-name{
    position: absolute;
    top: 40vh;
    left: 10vw;
    color: #fff;
    font-family: Inter;
}

.CRTDH-main {
    font-size: 10rem;
    margin: 0;
}
.CRTDH-main{
    margin-top: 10px;
    font-size: 10rem;
    font-family: 'Inclusive Sans';

}

.CRTDH-full-form {
    font-size: 2.7rem;
    font-family: 'Inclusive Sans';
}

.item-label {
    z-index: 100;
    font-size: 2rem;
}
.highlight {
    background-color: yellow; 
  }
.network{
    margin-left: 10px;
    margin-bottom: 0px;
    font-family: 'Inclusive Sans';
}
.JoinUs{
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    
}
.form-buttons{
    display: flex;
    justify-content:space-evenly;
    width: 40%;
    margin: 100px auto 0px;
    flex-wrap: wrap;
}
.joinUsButton{
    width: fit-content;
    min-width: 100px;
    height: 45px;
    padding: 8px;
    border-radius: 5px;
    border: 2.5px solid #E0E1E4;
    box-shadow: 0px 0px 20px -20px;
    cursor: pointer;
    background-color: white;
    transition: all 0.2s ease-in-out 0ms;
    user-select: none;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 10px;
}
.joinUsButtonn:hover {
    background-color: #F2F2F2;
    box-shadow: 0px 0px 20px -18px;
}
.active-join-btn{
    background-image: linear-gradient(180deg,#2bbe60,#0f9d58);
    color: #fff;
    border: 0px;
}  
.joinUsButton:active {
transform: scale(0.95);
}
.project-div{
    display: flex;
    margin-bottom: 40px;
}
.project-info{
   padding-right:20px ;
   width: 75%;
   text-align: justify;
}
.project-picture{
    width: 400px !important;
    object-fit: contain;
}
.read-more-link{
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
.active{
    text-decoration: underline !important;
}
.events-container{
    display: flex;
}
.event-img{
    width: 500px;
}
.event-img-div{
    display: flex;                                                                          
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.yt-video-industry-div{
    width: 100%;
    display: flex;
    margin-top: 30px;
    justify-content: center;
   
}
.react-photo-album--photo{
    padding: 15px;
    border: 3px solid #eee;
    border-radius: 10px;
    margin: 10px;
}
.gallery-videos{
    display: flex;
    overflow-x: scroll;
}
.gallery-video{
    height: 355px;
    width: 600px;
    margin: 20px;
    padding: 15px;
    border: 3px solid #eee;
    border-radius: 10px;
}
#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
@media all and (max-width:1200px) {
    .CRTDH-name {
        top: 22vh;
    }
    .project-div{
        flex-wrap: wrap;
        text-align: justify;
    }
    .project-picture{
        margin: auto;
    }
    .events-container{
        flex-wrap: wrap;
    }
    .event-img{
        width: 500px;
        margin: auto;
    }
    .yt-video-industry{
        width: 100%;
    }
}

@media all and (max-width:768px) {
    .CRTDH-name {
        left: 12vw;
    }

    .CRTDH-main {
        font-size: 10vw;
    }

    .CRTDH-full-form {
        font-size: 3vw;
    }
    .project-info{
        padding-right: 0px;
    }
    .event-img{
        width: 100%;
    }
    .yt-video-industry{
        width: 100%;
        height: 200px;
    }
}

@media all and (max-width:479px) {
    .CRTDH-name {
        top: 13vh;
        left: 4vw;
        text-align: center;
    }

    .CRTDH-main {
        font-size: 12vw;
    }

    .CRTDH-full-form {
        font-size: 4vw;
    }
    .project-picture{
        width: 90%;
        margin:auto;
    }
}

@media all and (max-height:670px) {
    .CRTDH-name {
        top: 18vh;
    }
}

.break{
    height: 2.8rem;
}