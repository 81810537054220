.about{
    width: 100vw;
    padding: 30px 5%;
    display: flex;
    flex-direction: column;
}
.about-us{
    font-family: Raleway;
    font-size: 4rem;
}
.separator_left {
    width: 120px;
    height: 2px;
    margin: 10px 0px;
    background: #ff6863;
}
.about-us-text{
    font-size: 2.2rem;
}
.CRTDH-labs-img{
    width: 50vw;
    margin: 0px auto;
}
@media all and (max-width:768px) {
    .about-us-text{
        font-size: 2rem;
    }
    .CRTDH-labs-img{
        width: 60vw;
    } 
}
@media all and (max-width:576px) {
    .about-us{
        font-size: 3rem;
    }
    .separator_left {
        width: 80px;
        margin: 5px 0px 10px 0px;
    }
    .about{
        padding: 30px 7%;
    }
    .about-us-text{
        font-size: 1.8rem;
    }
    .CRTDH-labs-img{
        width: 70vw;
    } 
}
@media all and (max-width:576px) {
    .about{
        padding: 30px 8%;
    }
    .about-us-text{
        font-size: 1.6rem;
    }
    .CRTDH-labs-img{
        width: 80vw;
    } 
}