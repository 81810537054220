/*Font Import*/
/*Font Import*/

@import url('https://fonts.googleapis.com/css?family=Merienda|Oswald:600,700|Roboto');

@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Open+Sans:wght@500&family=Poppins&family=Raleway:wght@500&display=swap');


/*Global Styles*/

.centers-dropdown {
	max-height: 400px;
	/* Set the maximum height for the dropdown */
	overflow-y: auto;
	/* Enable vertical scrolling when content exceeds the height */
}

.centers-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.centers-list li {
	padding: 8px 0;
}

.centers-list a {
	text-decoration: none;
	color: black;
	/* Set the text color to black */
}

.centers-list a:hover {
	background-color: #f0f0f0;
	/* Optional: Add a hover background color */
}

.centers-dropdown li{
	color: #000;
}


*,
:after,
:before {
	box-sizing: border-box;
}

.clear-all:after,
.clear-all:before {
	content: '';
	display: table;
	clear: both;
}

.cm-header-wrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 5px 15px;
}

.level2 {
	flex-direction: column;
}

.cm-menu-inner ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.cm-menu-inner>ul {
	display: block;
}

.cm-menu-inner>ul>li {
	float: left;
	padding: 8px 10px;
}

.cm-menu-inner>ul li a {
	display: block;
	font-weight: 500;
	font-family: Raleway;
	color: #fff;
	padding: 10px;
	font-size: 2rem;
	text-decoration: none;
	/* font-family: Inter; */
}

.links {
	font-size: 16px;
}

.comapny-logo {
	position: fixed;
	z-index: 100;
	width: 170px;
	top: 10px;
	/* left: 30px;
	top:25px; */
}

.searchbar {
	display: flex;
	align-items: center;
}

.CRTDH-logo-phone {
	display: none;
	color: #fff;
	font-size: 4rem;
	text-decoration: none !important;
	z-index: 2;
	position: absolute;
	top: 4px;
	left: 12px;
}

.search-text {
	margin-bottom: 0px;
}

.about-link {
	font-size: 2.2rem;
	padding: 12px 12px 14px 12px;
}
.activeHeader{
	text-decoration: underline;
}
@media(min-width: 992px) {

	.cm-menu-inner ul ul {
		position: absolute;
		top: 100%;
		left: 0;
		min-width: 130px;
		opacity: 0;
		visibility: hidden;
		transition: all 300ms linear;
	}

	.cm-menu-inner ul li.has-child {
		position: relative;
	}

	.cm-menu-inner ul li.has-child:hover>ul {
		opacity: 1;
		visibility: visible;
	}

	.cm-menu-inner ul ul ul {
		left: 100%;
		top: 0;
	}

	.cm-menu-inner {
		display: flex;
		justify-content: flex-end;
	}

	.cm-menu-inner {
		display: flex !important;
	}

	.cm-menu-inner ul {
		display: flex;
		align-items: center;
	}

	.cm-menu-btn,
	.child-menu-btn {
		display: none;
	}

}

@media(max-width: 991px) {
	.cm-header-wrap {
		height: 70px;
	}

	.comapny-logo {
		display: none;
	}

	.cm-menu-inner {
		position: absolute;

		width: 100%;
		left: 0;
		top: 100%;
		display: none;
	}

	.cm-menu-inner>ul>li {
		float: none;
		padding: 0;
	}

	.cm-menu-inner ul li>a {
		border-bottom: 1px solid #fff;
	}

	.cm-menu-inner ul ul>li>a {
		padding-left: 25px;
	}

	.cm-menu-inner ul ul ul>li>a {
		padding-left: 35px;
	}

	.cm-menu-btn {
		position: absolute;
		top: 25px;
		right: 30px;
		font-size: 25px;
		color: #fff;
		cursor: pointer;
	}

	li.has-child {
		position: relative;
	}


	.child-menu-btn {
		display: none;
		color: #000;
		position: absolute;
		right: 0;
		line-height: 40px;
		border-left: 1px solid #fff;
		width: 40px;
		text-align: center;
		cursor: pointer;
		top: 0;
	}

	.cm-menu-inner ul ul {
		display: none;
	}

	.CRTDH-logo-phone {
		display: block;
	}

	.clear-all,
	.cm-header-wrap {
		background-color: #fff !important;
	}

	.cm-menu-inner>ul li a,
	.searchbar,
	.search-text,
	.lucide-search,
	.CRTDH-logo-phone,
	.cm-menu-btn {
		color: #000 !important;
	}

	.CRTDH-logo-phone {
		z-index: 20;
		position: fixed;
	}
	.CRTDHs-link{
		color: #000 !important;
	}
	.centers-dropdown{
		display: block !important;
	}
}

/* Banner*/

.cm-header-wrap {
	z-index: 11;
}

.banner {
	position: relative;
	z-index: 10;
	height: 100vh;
	background-size: cover;
	background-position: center;
}

.banner h1 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 40px;
	text-align: center;
	background: #f6f8f9;
	padding: 16px;
	border-radius: 5px;
	color: #000;
	font-family: Oswald, sans-serif;
	letter-spacing: 1px;
	width: 700px;
	text-transform: capitalize;
}

.banner h1 a {
	color: #000;
	text-decoration: none;
	text-decoration: underline;
	display: inline-block;
	padding-top: 20px;
	font-size: 45px;
	text-shadow: 9px -1px 6px #000;
	transition: all 300ms ease;
}


.banner h1 a:hover {
	text-shadow: 5px -1px 14px #000;
}

@media(max-width: 767px) {

	.banner h1 {
		max-width: 90%;
		width: 100%;
	}
	.centers-dropdown{
		height: 160px;
	}

}