.footer{
    background-color: #000;
    width: 100vw;
    bottom: 0px;
}
.footer-text{
    margin: 0px;
    text-align: center;
    font-size: 2rem;
    padding: 10px;
    color: #fff
}
/* footer1 styles */
.CRTDH-desc{
    font-size: 1.8rem;
    font-family: 'open sans';
}
.CRTDH-name-footer1{
    font-size: 3rem;
    font-family: Raleway,sans-serif;
}
.quick-links{
    font-size: 2.2rem;
    padding-bottom: 20px;
}
.quick-links-link{
    text-decoration: none;
    color: #fff;
}
.head-office-address{
    font-size: 1.8rem;
}
.Head-office{
    font-size: 2rem;
    font-weight: 600;
}
@media all and (max-width:768px) {
    .footer-text{
        font-size: 3vw;
    }
}
@media all and (max-width:479px) {
   .quick-links-block{
    width: 100vw;
    padding: 20px 100px 20px 20px !important;
   }
}