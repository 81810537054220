.CRTDH-img{
    position: relative;
    /* top:100px; */
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}
.CRTDH-center-research-areas{
    width: fit-content;
    padding:20px 30px;
    background-color: #fff;
    position: absolute;
    font-family: 'open sans';
    bottom: 100px;
    left: 50px;
    border-radius: 5px;
}
.CRTDH-center-name{
    font-size: 3rem;
}
.research-area-name{
    font-size: 2rem;
}
.instruments-btn,.service-charges-btn,.pilot-plant-btn{
    color: #000000!important;
    border-width: 2px!important;
    border-radius: 6px;
    letter-spacing: 1px;
    font-size: 15px;
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif!important;
    font-weight: 600!important;
    text-transform: uppercase!important;
    line-height: 1.7em!important;
    background-color: transparent;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    border: 2px solid;
    box-shadow: 8px 8px 0px 0px rgba(0,0,0,0.3);
    padding: 10px 25px;
    transition: 400ms;
    text-decoration: none !important;
    position: relative;
    top: 20px;
}
.service-charges-btn{
    background-color: #0ca6d1;
    color: #fff !important;
    top: 50px;
}
.pilot-plant-btn{
    background-color: #410cc9;
    color: #fff !important;
    top: 80px;
}
.instruments-btn:hover{
    border: 2px solid #fff;
}
.service-charges-btn:hover{
    border: 2px solid #0ca6d1;
}
.Expected-Outcomes{
    font-weight: 600;
    font-size: 2.5rem;
}
@media all and (max-width:768px) {
    .instruments-btn,.service-charges-btn,.pilot-plant-btn{
        border: none;
        box-shadow: none;
        padding: 10px 25px;
        transition: 400ms;
        line-height: 3rem !important;
        }
    .instruments-btn{
        background-color: #eee;
    }
    .CRTDH-center-research-areas{
        width: 90%;
        padding: 10px 30px;
        bottom: 30px;
        height: 250px;
        overflow-y: scroll;
        left:5%;
        
    }
    .CRTDH-center-name{
        font-size: 2.5rem;
        font-weight: 600;
    }
    .research-areas{
        font-size: 2.3rem;
    }
}