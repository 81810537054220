.News-section{
    margin-top: 80px;
    display: flex;
    font-family: 'open sans';
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.media{
    font-weight: 600;
    font-size: 2rem;
}
.news{
    width: 350px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 3px solid #eee;
    border-radius: 15px;
}
.news-img{
    width: 100%;
    height: 200px;
    object-fit:cover;
    margin-bottom: 10px;
}
.news-info-text{
    font-size: 1.7rem;
    height: 100px;
    overflow-y:auto;
}
.newsLink{
    color: #000;
    text-decoration: none;
}
.newsLink:hover{
    text-decoration: none;
}